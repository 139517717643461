<template>
  <div class="bg-gray-100 securtrack_alarmcentral">
    <div class="gps-track">
    <!-- Row with button for showing all alarms, input for test alarm and test alarm button -->
      <div class="flex">
        <div class="mapsettings w-4/5 pt-2 pl-3">
          <button @click="toggleShowAlarm" :class="showAllAlarms ? 'btn-blue' : 'btn-secondary'" class="py-1 text-white rounded shadow transition duration-300">
            {{ showAllAlarms ? 'Dölj alla larm' : 'Visa alla larm' }}
          </button>
          <button @click="toggleSound" :class="soundOn ? 'btn-secondary' : 'btn-blue'" class="py-1 ml-2 text-white rounded shadow transition duration-300">
            {{ soundOn ? 'Sätt på ljud' : 'Stäng av ljud' }}
          </button>
        </div>
        <div class="flex justify-end w-full">
          <div v-if="showTestInput" class="flex gap-2">
            <div class="pt-2 w-2/4">
              <input type="text" v-model="inputPhone" field_name="" placeholder="Telefonnummer" class="py-1 pl-2 text-xs w-full bg-white rounded shadow"/>
            </div>
            <div class="pt-2">
              <input type="text" v-model="inputPassword" field_name="" placeholder="Behörighetskod" class="py-1 pl-2 text-xs w-full bg-white rounded shadow"/>
            </div>
            <div class="pt-2">
              <button @click="activateTestAlarm" class="py-1 btn-green">
                Aktivera
              </button>
            </div>
          </div>
        </div>
        <div class="buttoncontainer pt-2 pl-2 pr-3">
          <button @click="toggleInput" :class="showTestInput ? 'btn-red' : 'btn-blue'" class="py-1 text-white rounded shadow transition duration-300">
            {{ showTestInput ? 'Avbryt' : 'Provlarm' }}
          </button>
        </div> 
      </div>
      <!-- Section for map and alarm info -->
      <div class="map-alarm-container">
        <div class="map-container w-2/3">
          <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="google_map_wrapper_alarm_center shadow-inner" ref="map" :options="{ fullscreenControl: mapFullScreenControl }">
              <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"  @closeclick=" infoWinOpen = false; alarm_id = '';"> </GmapInfoWindow>
          <GmapCluster :zoom-on-click="true">
            <GmapMarker v-for="(m, index) in markers_filtered" :position="m.position" :clickable="true" :draggable="false" @mouseover="toggleInfoWindow(m, index)" @mouseout="toggleInfoWindow(m, index)" :key="index" :icon="{ url: m.icon, scaledSize: { width: 42, height: 50 }, anchor: { x: 21, y: 30 }  }"></GmapMarker>
          </GmapCluster>
          <GmapCircle v-for="(pin, index) in alarmCircles_filtered" :key="index" :center="pin.position" :visible="true" :options="pin.options"></GmapCircle>
          <GmapPolyline v-for="p in push_button_paths_filtered" :key="p.imei_number" :path="p.path" :options="{ strokeColor: 'red' }" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
          <GmapPolyline v-for="(p, i) in track_alarm_paths_filtered" :key="p.imei_number + `-${i}`" :path="p.path" :options="{ strokeColor: '#1a8de9' }" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
          </GmapMap>
        </div>
        <div class="bg-white alarm-info w-1/3 flex flex-col shadow-md">
          <div class="table-header bg-gray-200 font-bold flex flex-row border-b border-secondary-300">
            <div class="text-sm text-primary-700 ml-2">Larminformation</div>
          </div>
          <!-- Alarm Info -->
          <div v-if="!selectedAlarm" class="flex justify-center items-center h-full p-2">
            <p>Inget larm valt</p>
          </div>
          <div v-else class="flex-grow p-2">
            <div class="current_alarm_info h-80">
              <div class="alarm-details">
                <div class="column">
                  <p class="text-xs"><strong>Kundnamn:</strong> {{ selectedAlarm.customer_name || ''  }}</p>
                  <p class="text-xs"><strong>Enhetsnamn:</strong> {{ selectedAlarm.unit_name || ''  }}</p>
                  <p class="text-xs"><strong>Larmnamn:</strong> {{ selectedAlarm.alarm_name || ''  }}</p>
                </div>
                <div class="column">
                  <p class="text-xs"><strong>Telefon:</strong> {{ selectedAlarm.phone }}</p>
                  <p class="text-xs"><strong>Behörighetskod:</strong> {{ selectedAlarm.alarm_password || ''  }}</p>
                  <p class="text-xs"><strong>Utgår ifrån:</strong> {{ selectedAlarm.from_address.city || '' }}</p>
                </div>
                <div class="column">
                  <p class="text-xs"><strong>Bärare:</strong> {{ selectedAlarm.personal_alarm.name || ''  }}</p>
                  <p class="text-xs"><strong>Sysselsättning:</strong> {{ selectedAlarm.business_description || ''  }}</p>
                </div>
                <div class="column">
                  <p class="text-xs"><strong>Riskprofil:</strong> {{ selectedAlarm.risk_profile || ''  }}</p>
                  <p class="text-xs"><strong>Tag/Beacon:</strong> {{ selectedAlarm.presentTagName || ''  }}</p>
                </div>
              </div>
              <hr class="custom-line">
              <!-- If SecurPos is enabled and there is an active visit currently-->
               <div v-if="selectedAlarm.currentVisit">
                <p class="text-sm"><strong>Aktivt besök</strong></p>
               </div>
              <div v-if="selectedAlarm.currentVisit" class="flex gap-2">
                <div class="column">
                  <p class="text-xs"><strong>Adress:</strong> {{ selectedAlarm.currentVisit.address || ''  }}</p>
                  <p class="text-xs"><strong>Våning:</strong> {{ selectedAlarm.currentVisit.floor || ''  }}</p>
                  <p class="text-xs"><strong>Besök hos:</strong> {{ selectedAlarm.currentVisit.host || ''  }}</p>
                </div>
                <div class="column">
                  <p class="text-xs"><strong>Starttid:</strong> {{ selectedAlarm.currentVisit.startTime || ''  }}</p>
                  <p class="text-xs"><strong>Sluttid:</strong> {{ selectedAlarm.currentVisit.endTime || ''  }}</p>
                  <p class="text-xs"><strong>Bärare:</strong> {{ selectedAlarm.currentVisit.visitor || ''  }}</p>
                </div>
              </div>
              <hr class="custom-line" v-if="selectedAlarm.currentVisit">
              <div v-if="!hasValidInstructions">
                <p class="text-sm"><strong>Instruktioner</strong></p>
                <p class="text-xs">Primär insats:<br></p>
                <p class="text-xs">
                    Svara med mikrofon avstängd. Vid bekräftat hot eller våld, tillkalla polis och extern åtgörare enligt larmplan.
                    Larmoperatören spelar in samtalet och kommunicerar endast med personen efter upprepade försök eller om det är
                    bekräftat att personen är i trygghet.
                </p>
                <br>
                <p class="text-xs">Åtgärd vid larm<br></p>
                <ol class="text-xs">
                    <li>
                        <strong>1. Medlyssning:</strong> Bekräfta typ av larm (hot, våld, olycka eller fellarm).
                    </li>
                    <li>
                        <strong>2. Tillkalla:</strong> Polis och extern åtgörare vid bekräftat hot eller våld. Annars gå till steg 4.
                    </li>
                    <li>
                        <strong>3. Nödlarm:</strong> Kommunicera med personen, tillkalla ambulans och gå till steg 4.
                    </li>
                    <li>
                        <strong>4. Kontaktperson:</strong> Informera om skarpt larm. Vid oklarhet fråga om fellarm.
                    </li>
                    <li>
                        <strong>5. Återkalla:</strong> Larm kan återkallas med behörighetskod. Behörighetskod: <i>{{ selectedAlarm.alarm_password || ''  }}</i>
                    </li>
                </ol>
              </div>
              <!-- If custom instructions show this-->
              <div v-if="selectedAlarm.instructions">
                  <p class="text-xs" v-html="selectedAlarm.instructions"></p>
              </div>
              <br>
              <table class="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Namn</th>
                    <th>Funktion</th>
                    <th>Telefon</th>
                    <th>Vardag</th>
                    <th>Lördag</th>
                    <th>Söndag</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(person, index) in selectedAlarm.contact_persons" :key="index" v-if="person.name">
                    <td><b>{{ index + 1 }}</b></td>
                    <td>{{ person.name || 'N/A' }}</td>
                    <td>{{ person.function || 'N/A' }}</td>
                    <td>{{ person.phone || 'N/A' }}</td>
                    <td>{{ person.weekday || 'N/A' }}</td>
                    <td>{{ person.saturday || 'N/A' }}</td>
                    <td>{{ person.sunday || 'N/A' }}</td>
                  </tr>
                </tbody>
              </table>
              <hr class="custom-line">
              <div class="column">
                  <p class="text-xs"><strong>Vaktbolag:</strong> {{ selectedAlarm.external_contractor.name || ''  }}</p>
                </div>
                <div class="column">
                  <p class="text-xs"><strong>Vaktbolag tel:</strong> {{ selectedAlarm.external_contractor.phone || ''  }}</p>
                </div>
              <hr class="custom-line">
                <!-- Alarm interaction buttons -->
              <div class="button_container pb-2">
                <div class="mt-2" v-if="selectedAlarm.pushButton && selectedAlarm.acknowledge === false && !selectedAlarm.initTestAlarm">
                  <button class="btn-red" v-on:click.stop="confirmAcknowledgeAlarmPrompt(selectedAlarm.alarm_id, selectedAlarm.alarm_name)">Kvittera larm</button>
                </div>
                <!-- If alarm is acknowledge, show buttons-->
                <div class="mt-2 flex flex-row items-start gap-4" v-if="selectedAlarm.acknowledge && !selectedAlarm.initTestAlarm">
                  <button class="btn-secondary" v-on:click.stop="copyLink(selectedAlarm.gps_pos.lat, selectedAlarm.gps_pos.lng)">
                    Kopiera kartlänk
                  </button>
                  
                  <button
                    v-bind:class="selectedAlarm.tracking_active ? 'btn-red' : 'btn-green'"
                    v-on:click.stop="trackAlarm(selectedAlarm, selectedAlarm.alarm_index)"
                    v-if="(selectedAlarm.pushButton || selectedAlarm.geofenceExit || (selectedAlarm.speed > 5 && selectedAlarm.lastSeenTime < 1000 * 60 * 30) || selectedAlarm.tracking_active) && selectedAlarm.acknowledge">
                    <span v-if="selectedAlarm.tracking_active">{{ $t("secur_track.disable_tracking") }}</span>
                    <span v-else>{{ $t("secur_track.enable_tracking") }}</span>
                  </button>
                  
                  <button class="btn-blue" v-on:click.stop="confirmTestAlarmPrompt(selectedAlarm, selectedAlarm.alarm_index)">
                    {{ $t("secur_track.test_alarm_confirmed") }}
                  
                  </button>
                </div>
                <!-- If alarm is flaged as test and alarm received, only then show confirm test alarm button --> 
                <div class="mt-2" v-if="selectedAlarm.initTestAlarm && selectedAlarm.testAlarmReceived && !selectedAlarm.testConfirmed">
                  <button class="btn-blue" v-on:click.stop="confirmTestAlarmPrompt(selectedAlarm, selectedAlarm.alarm_index)">
                     {{ $t("secur_track.test_alarm_confirmed") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
    <div class="alarmview">
       <!-- List of all alarms connected to alarm central -->
      <div class="bg-white alarm-container w-3/5 shadow-md">
        <div class="alarm-list-wrapper" v-bind:class="{ search: search_active }" v-if="gps_alarms_filtered">
            <div class="bg-gray-200 font-bold flex flex-col sticky-header">
              <div class="flex flex-row items-center">
                <div class="text-sm w-4/5 text-primary-700 pl-2">Larmlista</div>
                <div class="text-sm w-1/5 text-primary-700">
                  <AlarmSearch @queryUpdated="handleSearch" class="py-2 text-sm"/>
                </div>
              </div>
              <div class="text-center border-b border-secondary-400" v-if="gps_alarms_filtered && gps_alarms_filtered.length == 0">
                <div class="py-3 w-full">{{ $t("no_alarm_found") }}</div>
              </div>
              <div class="flex flex-row">
                <div class="text-xs w-1/5 text-primary-700 pl-2">Kundnamn</div>
                <div class="text-xs w-1/5 text-primary-700">Enhetsnamn</div>
                <div class="text-xs w-1/5 text-primary-700">Larmnamn</div>
                <div class="text-xs w-1/5 text-primary-700">Telefon</div>
                <div class="text-xs w-1/5 text-primary-700">IMEI-nummer</div>
              </div>
            </div>

            <div v-for="(a, index) in filteredGPSAlarms" :key="index" :class="['hover:bg-primary-50', index % 2 === 0 ? 'bg-white' : 'bg-gray-100']">
              <div @click="focusAlarm(a.imei_number)" class="cursor-pointer border-b border-secondary-300 flex flex-row items-center" v-bind:class="{
                'bg-red-500 animate-pulse hover:bg-red-500': a.pushButton,
                'bg-primary-100': alarm_id == a.alarm_id && !a.pushButton
              }">
                <div class="text-sm mt-1 mb-1 font-bold w-1/5 flex items-center pl-2" v-bind:class="{'text-white': a.pushButton}">{{ a.customer_name }}</div>
                <div class="text-sm mt-1 mb-1 w-1/5 flex items-center" v-bind:class="{'text-white': a.pushButton}">{{ a.unit_name }}</div>
                <div class="text-sm mt-1 mb-1 w-1/5 flex items-center" v-bind:class="{'text-white': a.pushButton}">{{ a.alarm_name }}</div>
                <div class="text-sm mt-1 mb-1 w-1/5 flex items-center" v-bind:class="{'text-white': a.pushButton}">{{ a.phone }}</div>
                <div class="text-sm mt-1 mb-1 w-1/5 flex items-center" v-bind:class="{'text-white': a.pushButton}">{{ a.imei_number }}</div>
              </div>
            </div>
          </div>
      </div>

      <!-- List of acknowledged alarms -->
      <div class="bg-white alarm-container w-1/5 shadow-md">
        <div class="table-header bg-gray-200 font-bold flex flex-row border-b border-secondary-300 sticky-header">
          <div class="text-sm text-primary-700 ml-2">Kvitterade larm</div>
        </div>
        <div v-for="(a, index) in filteredAcknowledgedAlarms" :key="index" :class="['hover:bg-green-100', index % 2 === 0 ? 'bg-white' : 'bg-gray-100']">
          <div @click="focusAlarm(a.imei_number)" class="cursor-pointer border-b border-secondary-300 flex justify-between items-center p-2" :class="{'bg-green-500 hover:bg-green-400': a.pushButton}">

            <div class="text-sm w-2/4 text-left text-white">{{ a.alarm_name }}</div>
            <div class="text-sm w-1/4 text-center text-white">{{ a.phone }}</div>
            <div class="text-sm w-1/4 text-right text-white">{{ a.formattedPushBTimestamp }}</div>
          </div>
        </div>
      </div>


      <!-- List of test alarms -->
      <div class="bg-white alarm-container w-1/5 shadow-md">
        <div class="table-header bg-gray-200 font-bold flex flex-row border-b border-secondary-300 sticky-header">
          <div class="text-sm w-1/5 text-primary-700 ml-2">Provlarm</div>
        </div>
        <div v-for="(a, index) in filteredTestAlarms" :key="index" :class="[index % 2 === 0 ? 'bg-white' : 'bg-gray-100']">
          <div @click="focusAlarm(a.imei_number)" 
              class="cursor-pointer border-b border-secondary-300 flex flex-row items-center" 
              v-bind:class="{ 
                'hover:bg-primary-100': a.initTestAlarm && !a.testAlarmReceived,
                'bg-red-500 hover:bg-red-500 triggered': a.testAlarmReceived,
                'bg-green-500 hover:bg-green-400': a.testConfirmed
              }">
            <div class="text-sm mt-1 mb-1 ml-2 w-3/5 flex items-center" v-bind:class="{'text-white': a.testConfirmed || a.testAlarmReceived}">{{ a.alarm_name }}</div>
            <div class="text-sm mt-1 mb-1 w-1/5 flex items-center" v-bind:class="{'text-white': a.testConfirmed || a.testAlarmReceived}">{{ a.phone }}</div>
          </div>
        </div>
      </div>

    </div>
    <AlarmGPSInfo :alarmInfo="infoSRT326" />
  </div>
</template>

<script>
import AlarmGPSInfo from "@/components/modal/alarm_gps_info";
import { gmapApi } from "vue2-google-maps";
import "@googlemaps/markerclustererplus";
import AlarmSearch from "@/components/securetrack/alarm_search_lc";


export default {
  name: "AlarmCenterTrack",
  title() {
    return `SecurTrack | SecurCloud`;
  },
  components: {
    AlarmGPSInfo,
    AlarmSearch,
  },
  props: {
    customerId: {
      type: String,
    },
  },
  data() { 
    return {
      isMobile: false,
      mobileMenuActive: false,
      mapFullScreenControl: true,
      user: this.$store.state.user,
      alarm_center_id: this.$route.params.id,
      alarm_id: this.$route.params.alarm_id,
      firstLoad: true,
      alarm_center: null,
      customers: null,
      units: null,
      alarms: null,
      gps_alarms: [],
      gps_alarms_filtered: null,
      track_alarm: [],
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      markers: [],
      markers_filtered: [],
      refreshIntervalRef: null,
      refreshInterval: 10000, // every 10s
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      alarmCircles: [],
      alarmCircles_filtered: [],
      push_button_paths: [],
      push_button_paths_filtered: [],
      track_alarm_paths: [],
      track_alarm_paths_filtered: [],
      search_text: "",
      search_active: false,
      infoSRT326: null,
      searchQuery: "",
      selectedAlarm: null,
      showTestInput: false,
      inputPhone: '', 
      inputPassword: '',
      showAllAlarms: true,
      testConfirmed: false,
      testAlarmReceived: false,
      soundOn: false,
    };
  },
  methods: {
    async refreshGPSData() {
      this.refreshIntervalRef = setInterval(async () => {
        if (this.gps_alarms.length > 0) {
          try {
            await this.getAlarmCenterGPSAlarms();
          } catch (error) {
            this.handleError("Error when fetching GPS-data:", error);
          }
        }
      }, this.refreshInterval);
    },


    getAlarmCenterGPSAlarms() {
      var vm = this;
      
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-center/${this.alarm_center_id}/gps/alarms`)
        .then((response) => {
          this.alarm_center = response.data.alarm_center;
          this.customers = response.data.customers;
          this.units = response.data.units;
          this.alarms = response.data.alarms;
          this.track_alarm = response.data.track_alarm;
          this.alarmCircles = [];
          this.push_button_paths = [];
          this.track_alarm_paths = [];
          this.markers = [];

          var larms = [];
          var push_btn_larms = [];


          for (let i = 0; i < response.data.gps.length; i++) {
            let el = response.data.gps[i];
            let a = _.find(this.alarms, { imei_number: el.imei_number });

            if (a) {
              el.alarm_id = a._id;
              el.tracking_active = false;
              el.tracking_id = null;
              el.instructions = null;
              let unit = this.getUnit(a.parent_id);

              // Test alarm
              el.show_test_alarm = true;
              el.testConfirmed = false;
              el.testAlarmReceived = false;
              // Tag and SecurPos
              el.presentTagName = null;
              el.addons = a.addons;
              el.activeVisit = false;

              // gps
              let lastSeenObj = this.getAlarmLastSeenTime(el.updatedAt);
              el.lastSeen = lastSeenObj.lastSeen;
              el.lastSeenTime = lastSeenObj.lastSeenTime;
              el.speed = el.alarm_speed;
              
              if (new RegExp(/^SRT/g).test(a.type)) el.speed = (el.alarm_speed * 1.852).toFixed(2);

              // Teltonika vehical speed
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.speed = el.teltonika_other_data.vehicle_speed || 0;

              // alarm
              el.alarm_name = a.unit_name;
              el.phone = a.phone;
              el.unit_id = a.parent_id;
              el.type = a.type;
              el.from_address = a.from_address;
              el.contact_persons = a.contact_persons;
              el.alarm_password = a.alarm_password;
              el.name = a.name;
              el.external_contractor = a.external_contractor;
              el.business_description = a.business_description;
              el.risk_profile = a.risk_profile;
              el.personal_alarm = a.personal_alarm;
              el.formattedPushBTimestamp = el.lastPushButtonTimestamp ? new Date(el.lastPushButtonTimestamp).toLocaleTimeString("sv-SE", {hour: "2-digit", minute: "2-digit",}) : "N/A";

              // battery
              el.batteryPercent = el.battery_percent;

              // Teltonika battery percantage
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.batteryPercent = Math.min(Math.floor(el.battery_voltage * 100 - 323), 100);

              let batteryBars = 0;
              if (el.batteryPercent > 80) batteryBars = 4;
              else if (el.batteryPercent > 50) batteryBars = 3;
              else if (el.batteryPercent > 25) batteryBars = 2;
              else if (el.batteryPercent > 10) batteryBars = 1;
              el.batteryBars = batteryBars;

              // gsm signal
              let signalPercent = 0;
              if (new RegExp(/^SRT/g).test(a.type)) {
                if (el.gsm_signal <= 31) signalPercent = Math.ceil(3.225 * el.gsm_signal);
                let signalBars = 0;
                if (signalPercent > 80) signalBars = 4;
                else if (signalPercent > 50) signalBars = 3;
                else if (signalPercent > 25) signalBars = 2;
                else if (signalPercent > 0) signalBars = 1;
                el.signalBars = signalBars;
              } else if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                //gsm signal for Teltonika
                let signalBars = el.gsm_signal - 1 > 0 ? el.gsm_signal - 1 : 0;
                el.signalBars = signalBars;
              }

              // Tag. Consider tag as valid if 5 min or less has passed since last tag timestamp.
              if (el.tag_id && el.tag_timestamp && new Date().getTime() - new Date(el.tag_timestamp).getTime() < 300000) {

                let deviceTagId = el.tag_id;
                if (new RegExp(/^SRT/g).test(el.type)) {
                  deviceTagId = el.tag_id.slice(2)
                }

                let matchedTagInfo = this.alarms.filter(alarm => alarm.modelnumber === deviceTagId);

                if (matchedTagInfo.length > 0) { 
                  el.presentTagName = matchedTagInfo[0].unit_name; 
                } else {
                  console.log("Ingen matchande tagg hittades för ID: " + el.tag_id);
                }
              }

              //Teltonika Odometer in Km
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.totalOdometer = el.teltonika_other_data.obd_oem_total_mileage;

              //Teltonika Fuel Level in Ltr
              if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                el.obdOemFuelLevel = el.teltonika_other_data.odb_oem_fuel_level * 0.1 || 0;
              }

              // accuracy radius
              el.accuracyRadius = 0;
              if (new RegExp(/^SRT/g).test(a.type)) {
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              } else if (new RegExp(/^TWIG/g).test(a.type)) el.accuracyRadius = el.hdop;
              // twig devices
              else if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                // TELTONIKA devices
                // satellites, hdop
                el.satellites = el.satellites || 0;
                el.hdop = el.hdop * 0.1 || 0;
                // accuracy radius
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              }

              el.unit_name = unit ? unit.name : "";
              el.customer_id = unit ? unit.parent_id : "";
              el.customer_name = unit ? this.getCustomerName(unit.parent_id) : "";

              // Set icon depending on alarm model
              
              if (a.type != "SRT326" && el.gps_pos.lat != null) {

                let icon = require("@/assets/icon/new_small_map_marker.png");
                if (new RegExp(/^TELTONIKA/g).test(el.type)) icon = require("@/assets/icon/car.png");
                if (a.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
                if (a.type == "SRT341") icon = require("@/assets/icon/srt341.png");
                if (a.type == "SRT406") icon = require("@/assets/icon/small_SRT406new.png");
                if (a.type == "SRT406i") icon = require("@/assets/icon/small_SRT406new.png");
                if (/^TWIG/.test(a.type)) {
                  icon = require("@/assets/icon/twig_map_marker.png");

                  if (a.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                  else if (/^TWIG One/.test(a.type)) icon = require("@/assets/icon/twig_one.png");
                }
                else if (/^EMERIT/.test(a.type)) {
                  icon = require("@/assets/icon/emerit_watch.png");

                  if (a.type == "EMERIT e-B10") icon = require("@/assets/icon/smartphone.png");
                }

                this.markers.push({
                  icon: icon,
                  position: { lat: el.gps_pos.lat ? el.gps_pos.lat : 0, lng: el.gps_pos.lng ? el.gps_pos.lng : 0 },
                  infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${el.alarm_name}</b><br>
                          ${this.$t("map_info_popup.gsm_signal_strength")}: <b>${this.getGSMSignalPercent(el.gsm_signal, el.type)}</b><br>
                          ${this.$t("map_info_popup.battery_percentage")}: <b>${el.batteryPercent} %</b><br>
                          ${this.$t("map_info_popup.speed")}: <b>${el.speed} km/hr</b><br>
                         ${this.$t("map_info_popup.lat")}: <b>${el.gps_pos.lat ? el.gps_pos.lat.toFixed(3) : "-"}</b>, Lng: <b>${el.gps_pos.lng ? el.gps_pos.lng.toFixed(3) : "-"}</b>
                          `,
                  alarmId: a._id,
                  imei_number: a.imei_number,
                });
               
                // push-button tracking 30 min.
                if (el.lastPushButtonTimestamp && new Date().getTime() - el.lastPushButtonTimestamp < 1800000) {
                  let push_button_path = _.find(this.track_alarm, { _id: el.lastPushButtonTrackID });
                  if (push_button_path) {
                    let path = _.map(push_button_path.gps_path, "position");
                    this.push_button_paths.push({
                      path: path,
                      alarmId: el.alarm_id,
                      imei_number: el.imei_number,
                      infoText: `<b>Push-Button spårning - ${el.alarm_name}</b>`,
                    });
                  }
                }
                
                // track user 30 min.
                if (el.alarm_track_user)
                  el.alarm_track_user.forEach((track_path) => {
                    if (new Date().getTime() - new Date(track_path.timestamp).getTime() < 1800000) {
                      let track_alarm = _.find(this.track_alarm, { _id: track_path.tracking_id, user_name: this.user.username });

                      if (track_alarm) {
                        let path = _.map(track_alarm.gps_path, "position");
                        this.track_alarm_paths.push({
                          path: path,
                          alarmId: el.alarm_id,
                          imei_number: el.imei_number,
                          infoText: `<b>Spårning - ${el.alarm_name}</b>`,
                        });

                        el.tracking_active = true;
                        el.tracking_id = track_path.tracking_id;
                      }
                    }
                  });
              }

              el.geofenceExit = false;
              if (el.lastGeofenceExit && this.moment().diff(this.moment(el.lastGeofenceExit), "minutes") <= 30) {
                el.geofenceExit = true;

                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: el.imei_number,
                  alarmId: el.alarm_id,
                  options: {
                    fillColor: "orange",
                    fillOpacity: 0.2,
                    strokeColor: "orange",
                    strokeOpacity: 0.3,
                    strokeWeight: 2,
                    radius: 50,
                  },
                });
              }
              // If timestamp of last test alarm is later than the timestamp for the activation of test alarm and within 10 min range
              // Set testConfirmed to true.
              let lastConfrimedTestTime = new Date(a.last_test_alarm).getTime();
              let lastInitialForTest = new Date(el.lastInitTestAlarmTimeStamp).getTime();
              if (!isNaN(lastConfrimedTestTime) && !isNaN(lastInitialForTest) && lastConfrimedTestTime > lastInitialForTest) {
                this.$set(el, 'testConfirmed', true);
              }
              // To set device in test list.
              el.initTestAlarm = false;
              if (el.lastInitTestAlarmTimeStamp) {
                if ((Date.now() - new Date(el.lastInitTestAlarmTimeStamp)) <= 10 * 60 * 1000 && !el.testConfirmed) {
                  el.initTestAlarm = true;
                }
              }
              // Get device risk instruction only if instruction is not empty
            if (el.risk_profile === 'Hotlarm' && this.alarm_center.threat_instruction && this.alarm_center.threat_instruction.trim() !== '') {
              el.instructions = this.alarm_center.threat_instruction;
            }
            if (el.risk_profile === 'Nödlarm' && this.alarm_center.emergency_instruction && this.alarm_center.emergency_instruction.trim() !== '') {
              el.instructions = this.alarm_center.emergency_instruction;
            }
            if (el.risk_profile === 'Överfallslarm (ej återkallningsbart)' && this.alarm_center.assault_instruction && this.alarm_center.assault_instruction.trim() !== '') {
              el.instructions = this.alarm_center.assault_instruction;
            }
            if (el.risk_profile === 'Malmö Fast' || el.risk_profile === 'Malmö Rörlig' || el.risk_profile === 'Malmö nödsändare') {
              el.instructions = "Ingen instruktion"
            }
             
              //Push B event
              el.pushButton = false;  
              if (el.lastPushButtonTimestamp && !isNaN(new Date(el.lastPushButtonTimestamp).getTime())) {

                if (el.lastPushButtonTimestamp && new Date().getTime() - el.lastPushButtonTimestamp < 1800000) {
         
                  
                  let pushButtonTime = new Date(el.lastPushButtonTimestamp);
                  let initTestTime = new Date(el.lastInitTestAlarmTimeStamp);
                  let lastTestAlarmTime = new Date(a.last_test_alarm);
                  let acknowledgeTime = new Date(el.lastAcknowledgeTimeStamp);

                  if ((Date.now() - initTestTime) <= 10 * 60 * 1000 && !el.testConfirmed && initTestTime < pushButtonTime) {
                    el.testAlarmReceived = true;
                    el.pushButton = false;

                  } else if (((pushButtonTime - initTestTime >= 10 * 60 * 1000) || el.testConfirmed) && (pushButtonTime > lastTestAlarmTime)) {
                    el.pushButton = true;
                  }
                  
  
                  if (a.type != "SRT326") {
                    this.alarmCircles.push({
                      position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                      imei_number: el.imei_number,
                      alarmId: el.alarm_id,
                      options: {
                        fillColor: "#FF0000",
                        fillOpacity: 0.1,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.2,
                        strokeWeight: 2,
                        radius: 50,
                      },
                    });
                  }
                  let diffInMinutes = null;
                  el.acknowledge = false;
                  if (el.lastAcknowledgeTimeStamp) {
                    if (acknowledgeTime > pushButtonTime) {
                      el.acknowledge = true;

                      const currentTime = new Date();
                      diffInMinutes = (currentTime - acknowledgeTime) / (1000 * 60);
                      // If acknowledge timestamp is more than 30 mins old, set to false and alarm will transfer to normal alarm list.
                      if (diffInMinutes > 30) {
                        el.acknowledge = false;
                        el.pushButton = false;
                        this.removeAlarmCircle(el.imei_number);
                      }
                      if (lastTestAlarmTime > acknowledgeTime) {
                        el.acknowledge = false;
                        el.pushButton = false;
                      }
                    } 
                  }
                  // If pushbutton is true and alarm is not in test mode or acknowledged play sound.
                  // soundOn boolean can be toggled by user and decides if sound should play or not.
                  
                  if (el.pushButton && !el.acknowledge && !el.initTestAlarm) {
                    if (!this.soundOn && !this.alarmAudio) { 
                      this.playAlarmSound();
                    }
                  } else {
                    this.stopAlarmSound();
                  }


                  // test-alarm-btn
                  // check if push button or mandown
                  if (el.lastPushButtonTimestamp != el.lastMandownTimestamp) {
                    // todo: fix logic for showing btn if push btn is pressed repeat
                    if (Math.abs(new Date(a.last_test_alarm).getTime() > new Date(el.lastPushButtonTimestamp).getTime())) el.show_test_alarm = false;
                    else if (a.last_test_alarm == null) el.show_test_alarm = true;
                  }

                  //Verify that the linked unit includes the SecurPos addon to prevent unnecessary API calls.
                  if (unit.addons && unit.addons.includes("securpos")) {
                    el.hasSecurpos = true;
                  } else {
                    el.hasSecurpos = false;
                  } 

                  // API call to fetch SecPos data will only run if pushB is true or test alarm recieved and alarm has not been acknowledged.
                  // By doing this, we do not do unnecessary calls
                  let pushEventAndSecOpsAndWithin = el.pushButton && el.hasSecurpos && this.moment().isBetween(this.moment(el.lastPushButtonTimestamp), this.moment(el.lastPushButtonTimestamp).add(30, 'minutes'));
                  let testAlarmReceivedSecOps = el.testAlarmReceived && el.hasSecurpos;
                  let newlyAcknowledged = diffInMinutes < 30;

                  if ((pushEventAndSecOpsAndWithin) || testAlarmReceivedSecOps || (newlyAcknowledged && (lastTestAlarmTime < acknowledgeTime))) {
                    this.getSecurPosVisit(el.phone).then((response) => {
                      if (response.success) {
                        el.activeVisit = true;
                        let visit = response.visit;
                        
                        let startTime = new Date(visit.startTime); 
                        const endTime = new Date(startTime.getTime() + visit.timeLength * 1000);

                        const formattedStartTime = this.moment(startTime).format("YYYY-MM-DD HH:mm");
                        const formattedEndTime = this.moment(endTime).format("YYYY-MM-DD HH:mm");

                        el.currentVisit = {
                        address: visit.address.area || "",      
                        floor: visit.address.floor || "",     
                        host: visit.address.building || "",
                        visitor: visit.address.visitor || "",
                        startTime: formattedStartTime || "",  
                        endTime: formattedEndTime || "" 
                      };
                      } else {
                        console.error("Inget aktivt besök hittades.");
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  }
                  push_btn_larms.push(el);
                  } else larms.push(el);
              }
              else larms.push(el);

              if (el.pushButton == false && el.accuracyRadius > 0 && a.type != "SRT326") {
                // accuracy circle
                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: el.imei_number,
                  alarmId: el.alarm_id,
                  options: {
                    fillColor: "#ffce73",
                    fillOpacity: 0.2,
                    // strokeColor: '#ffc250',
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    radius: el.accuracyRadius,
                  },
                });
              }
            }
          }

          push_btn_larms = push_btn_larms.sort(function(a, b) {
            return b.lastPushButtonTimestamp - a.lastPushButtonTimestamp;
          });
          this.gps_alarms = [...push_btn_larms, ...larms];

          if (this.search_active) {
            this.searchAlarms();
          } else {
            this.gps_alarms_filtered = this.gps_alarms;
            
            if (this.showAllAlarms) {
              this.markers_filtered = this.markers;
              this.alarmCircles_filtered = this.alarmCircles;
              this.push_button_paths_filtered = this.push_button_paths;
              this.track_alarm_paths_filtered = this.track_alarm_paths;
            } else {
              if (this.selectedAlarm) {
                const selectedMarker = this.markers.find(marker => marker.imei_number === this.selectedAlarm.imei_number);
                this.markers_filtered = selectedMarker ? [selectedMarker] : [];
                this.alarmCircles_filtered = this.alarmCircles.filter(circle => circle.imei_number === this.selectedAlarm.imei_number);
                this.push_button_paths_filtered = this.push_button_paths.filter(path => path.imei_number === this.selectedAlarm.imei_number);
                this.track_alarm_paths_filtered = this.track_alarm_paths.filter(path => path.imei_number === this.selectedAlarm.imei_number);
              } else {
                this.markers_filtered = [];
                this.alarmCircles_filtered = [];
                this.push_button_paths_filtered = [];
                this.track_alarm_paths_filtered = [];
              }
            }
          }


          if (this.firstLoad) {
            if (this.alarm_id) {
              this.$nextTick().then(() => {
                let a = _.find(this.gps_alarms, { _id: vm.alarm_id });
                if (a) vm.focusAlarm(a.imei_number);
              });
            } else {
              this.generateBounds();
            }
            this.firstLoad = false;
          }

          if (document.hasFocus()) this.handleSuccess("Plats uppdaterad", "top-left", 1000);
        })
        .catch((error) => {
          this.handleError(error, "Fel när plats hämtades");
        });
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
        if (!this.infoWinOpen) this.alarm_id = "";
        else this.alarm_id = marker.alarmId;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        this.alarm_id = marker.alarmId;
      }
    },

    showInfoWindow(event, infoText, show) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
      } else this.infoWinOpen = show;
    },

    focusAlarm(imei) {
      let alarmIndex = this.gps_alarms.findIndex(alarm => alarm.imei_number === imei);
      if (alarmIndex !== -1) {
        let selectedMarker = this.markers.find(marker => marker.imei_number === imei);
        this.markers_filtered = [selectedMarker];
        this.alarmCircles_filtered = this.alarmCircles.filter(circle => circle.imei_number === imei);
        this.push_button_paths_filtered = this.push_button_paths.filter(path => path.imei_number === imei);
        this.track_alarm_paths_filtered = this.track_alarm_paths.filter(path => path.imei_number === imei);

        this.$refs.map.$mapObject.panTo(selectedMarker.position);
        this.$refs.map.$mapObject.setZoom(18);
      

        // Put selectedAlarm and index into variable.
       this.selectedAlarm = { ...this.gps_alarms[alarmIndex], alarm_index: alarmIndex };
      }
    },

    showSRT326(alarm) {
      this.infoSRT326 = alarm;
      this.$modal.show("modal-info-srt326");
    },

    getGSMSignalPercent(g, type) {
      if (new RegExp(/^TELTONIKA/g).test(type)) {
        return g * 20 + "%";
      }
      {
        // conversion as per SRT documentation
        if (g <= 31) return Math.ceil(3.225 * g) + " %";
        else return "N/A";
      }
    },

    getUnit(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u;
      else return null;
    },

    getCustomerName(id) {
      let c = _.find(this.customers, { _id: id });
      if (c) return c.name;
      else return "";
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        lastSeen: this.moment(updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(updatedAt)),
      };
    },

    async trackAlarm(gps, alarm_index) {
      try {
        if (!gps.tracking_active) {
          // start tracking
          let alarm = _.find(this.alarms, { imei_number: gps.imei_number });

          let speed = parseFloat(gps.alarm_speed);
          if (alarm && new RegExp(/^SRT/g).test(alarm.type)) speed = (parseFloat(gps.alarm_speed) * 1.852).toFixed(2);

          let data = {
            position: gps.gps_pos,
            speed: speed,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/gps/track-alarm/start/${gps.imei_number}`, data);
          let track_alarm = response.data.track_alarm;
          let path = _.map(track_alarm.gps_path, "position");

          this.track_alarm_paths.push({
            path: path,
            imei_number: gps.imei_number,
            alarmId: gps.alarm_id,
            infoText: `<b>Spårning - ${gps.alarm_name}</b>`,
          });

          this.gps_alarms_filtered[alarm_index].tracking_active = true;
          this.gps_alarms_filtered[alarm_index].tracking_id = track_alarm._id;
          this.$set(this.selectedAlarm, 'tracking_active', true);

          this.focusAlarm(gps.imei_number);
          this.handleSuccess(`Spårning startad: ${gps.alarm_name}`);
        } else {
          // stop tracking
          let data = {
            tracking_id: gps.tracking_id,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/gps/track-alarm/stop/${gps.imei_number}`, data);
          if (response.data.success) {
            this.track_alarm_paths = _.remove(this.track_alarm_paths, { imei_number: gps.imei_number });
            this.gps_alarms_filtered[alarm_index].tracking_active = false;
            this.gps_alarms_filtered[alarm_index].tracking_id = null;
            this.handleSuccess(`Spårning avslutad: ${gps.alarm_name}`);
            this.$set(this.selectedAlarm, 'tracking_active', false);
          }
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    confirmTestAlarmPrompt(alarm, alarm_index) {
      this.$modal.show("dialog", {
        title: `Är du säker?`,
        text: `<p style="text-align: center;">Bekräfta testlarm för ${alarm.alarm_name}</p>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">NEJ</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">JA</div>',
            handler: () => {
              this.confirmTestAlarm(alarm, alarm_index);
            },
          },
        ],
      });
    },

    async confirmTestAlarm(alarm, alarm_index) {
      // Saves last_test_alarm timestamp in Alarm collection. Also creates a post in TestAlarmLog.
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarm/confirm-test-alarm/${alarm.alarm_id}`);

        this.$modal.hide("dialog");

        if (response.data.success) {
          this.$set(this.gps_alarms[alarm_index], 'testConfirmed', true);
          this.$set(this.selectedAlarm, 'testConfirmed', true);
          this.$set(this.selectedAlarm, 'acknowledge', false);
          this.$set(this.selectedAlarm, 'pushButton', false);

          this.alarmCircles = this.alarmCircles.filter(circle => circle.imei_number !== alarm.imei_number);
          this.alarmCircles_filtered = this.alarmCircles_filtered.filter(circle => circle.imei_number !== alarm.imei_number);

          // Reload to update UI
          this.getAlarmCenterGPSAlarms();
          this.handleSuccess(`Provlarm Bekräftat för ${alarm.unit_name}`);
        }
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    // Acknowledge alarm logic

    confirmAcknowledgeAlarmPrompt(id ,name) {
      this.$modal.show("dialog", {
        title: `Kvittens av larm`,
        text: `<p style="text-align: center;">Kvittera inkommet larm från ${name}?<br><br> <br>
          När larmet är kvitterat kan du kopiera en länk till google maps, markera som provlarm eller aktivera spårning av larm.</p> `,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">NEJ</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">JA</div>',
            handler: () => {
              // Call function to store confirm in DB. 
              this.confirmAcknowledgeAlarm(id, name);
            },
          },
        ],
      });
    },

    async confirmAcknowledgeAlarm(id, name) {
      let alarmId = id;

      try {
        /*
        Api call to store acknowledge timestamp in GPS collection. If success it stops the alarm
        and fetch alarm center alarms again to refresh data.
        */
        let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm/acknowledge-alarm/${alarmId}`);
        this.$modal.hide("dialog");

        // if response is false, the alarm has already been acknowledged. Shows message is toaster.
        
        if (response.data.success) {
          this.handleSuccess(`Larm: ${name} kvitterat`);
          this.stopAlarmSound();
          this.getAlarmCenterGPSAlarms();
          this.$set(this.selectedAlarm, 'acknowledge', true);
        }
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    async copyLink() {
      try {
          let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/gps/generateLink/${this.selectedAlarm.imei_number}`);
        
          navigator.clipboard.writeText(response.data.link).then(() => {
            this.handleSuccess(`Länk kopierad`);
          })
          .catch(err => {
            this.handleError('Fel vid kopiering av spårningslänk: ', err);
          });
        } catch (error) {
          this.handleError(error);
        }
    },


    generateBounds() {
      var vm = this;
      setTimeout(() => {
        vm.$refs.map.$mapPromise.then((map) => {
          const bounds = new vm.google.maps.LatLngBounds();
          for (let m of this.markers) {
            bounds.extend(m.position);
          }
          map.fitBounds(bounds);
        });
      }, 500);
    },

    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();
      this.mobileMenuDiv = controlDiv;

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(this.mobileMenuDiv);
      });
    },
    handleSearch(newQuery) {
      this.searchQuery = newQuery;
    },
    toggleInput() {
      // Show or hide intput fields for test alarm.
      this.showTestInput = !this.showTestInput;
    },
    async activateTestAlarm () {

      /*
      Stores a timestamp in GPS colletion. Function for transfer alarm to test alarm column. 
      The alarm will stay in this column for 10 mins. If new alarm is received after the test,
      is considered as a new alarm and will show red.
      */
      let alarmIndex = this.gps_alarms.findIndex(alarm => {
        let phoneMatch = alarm.phone && alarm.phone.includes(this.inputPhone.trim());
        let passwordMatch = alarm.alarm_password && alarm.alarm_password.trim().toLowerCase() === this.inputPassword.trim().toLowerCase();

        return phoneMatch && passwordMatch;
      });
      if (alarmIndex !== -1) {
        try {
          // API call to store timestamp for the test alarm in GPS collection.
          await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm/init-test/${this.gps_alarms[alarmIndex].imei_number}`);

          this.$set(this.gps_alarms[alarmIndex], 'testConfirmed', false);
          // Clear input fields
          this.inputPhone = '';
          this.inputPassword = '';
          // Confirm success and refresh alarms to update UI.
          this.handleSuccess(`Provlarm aktiverat`);
          this.getAlarmCenterGPSAlarms();
        } catch (error) {
          this.handleError(error);
        }
      } else {
        console.error('Fel: IMEI eller lösenord matchar inte');
      }
    },
    playAlarmSound() {
      try {
        setTimeout(() => {
          if (!this.alarmAudio) {
            this.alarmAudio = new Audio(require('@/assets/audios/emergency-alarm.mp3'));
            this.alarmAudio.loop = true;
          }
          this.alarmAudio.play().catch(error => this.handleError("Error when playing sound: ", error));
        }, 500); // Wait 500ms before playback to counter the autoplay obstacle in browsers
      } catch (error) {
        this.handleError("Error when playing sound: ", error);
      }
    },


    stopAlarmSound() {
      if (this.alarmAudio) {
        this.alarmAudio.pause();
        this.alarmAudio.currentTime = 0; 
        this.alarmAudio = null;
      }
    },
    toggleShowAlarm() {
      // To show or hide multiple markers on map.
      this.showAllAlarms = !this.showAllAlarms;

      if (this.showAllAlarms) {
        this.markers_filtered = this.markers;
        this.alarmCircles_filtered = this.alarmCircles;
        this.push_button_paths_filtered = this.push_button_paths;
        this.track_alarm_paths_filtered = this.track_alarm_paths;
      } else {
        if (this.selectedAlarm) {
          const selectedMarker = this.markers.find(marker => marker.imei_number === this.selectedAlarm.imei_number);
          this.markers_filtered = selectedMarker ? [selectedMarker] : [];
          this.alarmCircles_filtered = this.alarmCircles.filter(circle => circle.imei_number === this.selectedAlarm.imei_number);
          this.push_button_paths_filtered = this.push_button_paths.filter(path => path.imei_number === this.selectedAlarm.imei_number);
          this.track_alarm_paths_filtered = this.track_alarm_paths.filter(path => path.imei_number === this.selectedAlarm.imei_number);
        } else {
          this.markers_filtered = [];
          this.alarmCircles_filtered = [];
          this.push_button_paths_filtered = [];
          this.track_alarm_paths_filtered = [];
        }
      }
    },
    toggleSound () {
      this.soundOn = !this.soundOn;
    },

    async getSecurPosVisit(phone) {
      try {
        if (phone) {
          let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/securpos/getsecurpos/${phone}`);
          return response.data;
        }
      } catch (error) {
        this.handleError("Error in getSecurPosVisist:", error);
        return Promise.resolve({ success: false, visit: null });
      }
    },

    removeAlarmCircle(imei) {
      this.alarmCircles = this.alarmCircles.filter(circle => circle.imei_number !== imei);
    }
  },

  computed: {
    google: gmapApi,
    filteredGPSAlarms() {
      // If no active search, return complete list of alarms with exceptions. 
      if (!this.searchQuery) {
        return (this.gps_alarms || []).filter((item) => !item.acknowledge && !item.initTestAlarm); // If no search query, return all items that is not acknowledged or in test state.
      } 
      // If active search - set input to lowercase and return match.
      return this.gps_alarms.filter((item) => {
        const searchQuery = this.searchQuery.toLowerCase();

        const matchesSearch =
            item.customer_name.toLowerCase().includes(searchQuery) ||
            item.unit_name.toLowerCase().includes(searchQuery) ||
            item.phone.includes(searchQuery) ||
            item.imei_number.includes(searchQuery) ||
            item.alarm_name.toLowerCase().includes(searchQuery);

        return matchesSearch && (!item.acknowledge && !item.initTestAlarm);
    });
    },
    filteredAcknowledgedAlarms() {
      // Filter alarms. Find all acknowledged alarm with acknowledge set to true.
      return this.gps_alarms.filter((alarm) => alarm && alarm.acknowledge === true);
    },
    filteredTestAlarms() {
      let filteredTestAlarms = this.gps_alarms.filter(alarm => alarm && alarm.initTestAlarm === true && !alarm.testConfirmed);
      return filteredTestAlarms;
    },
    hasValidInstructions() {
      const html = this.selectedAlarm.instructions || '';
      const textOnly = html.replace(/<[^>]*>/g, '').trim();
      return textOnly.length > 0;
    }
  },

  created() {
    this.getAlarmCenterGPSAlarms();
    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.refreshGPSData();
    this.generateMobileMenu();
    this.checkMobile();

    this.$nextTick(() => {
    if (this.$refs.map && this.$refs.map.$mapPromise) {
      this.$refs.map.$mapPromise.then((map) => {
        this.map = map;
      }).catch((err) => this.handleError("Error when loading Google Maps:", err));
    }
  });
  },

  beforeDestroy() {
    clearInterval(this.refreshIntervalRef);
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>
