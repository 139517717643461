<template>
    <div class="pr-5">
      <div>
        <input v-model="internalQuery" type="text" field_name="" :placeholder="$t('search_filter')" @input="updateQuery" class="py-1 pl-2 text-sm w-full bg-white rounded shadow"/>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        internalQuery: "",
      };
    },
    methods: {
      updateQuery() {
        this.$emit("queryUpdated", this.internalQuery);
      },
    },
  };
  </script>
  